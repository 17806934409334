/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { BASE_URL } from '../config';
import axios from 'axios';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Grid, Button, Select, MenuItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom"; 
import "../Components/App.css";

const BOQpage = () => {
  const location = useLocation();
  const { User_ID } = location.state;
  const [project, setProject] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null);
  const [projectCodes, setProjectCodes] = useState([]);
  const [data, setData] = useState([]);
  const [CProject, setCProject] = useState([]);
  const GetitemCode = (e) => {
    const selectedCode = e.target.value; 
    setSelectedProjectCode(selectedCode);
  };

  const CalculationAmount = (Qty, Rate) => {
    const parsedQuantity = parseFloat(Qty) || 0;
    const parsedRate = parseFloat(Rate) || 0;
    const multi = parsedQuantity * parsedRate;
    return multi;
  };

  const handleBlur = (index) => {
    const newData = [...data];
    newData[index].amount = CalculationAmount(newData[index].Quantity, newData[index].rate);
    setData(newData);
  };

  const handleRateChange = (index, value) => {
    const newData = [...data];
    newData[index].rate = value;
    setData(newData);
  };

  const saveDataToDatabase = async () => {
    try {
      const dataToSave = data.map((item) => ({
        Item_Code: item.Item_Code,
        Item_Name: item.Item_Name,
        Item_Description: item.Item_Description,
        Item_Unit: item.Item_Unit,
        Quantity: item.Quantity,
        Rate: item.rate,
        Value: item.amount,
        Transaction_No: CProject[0].Transaction_No,
        Project_Code: selectedProjectCode,
        Project_Name: CProject[0].Project_Name,
      }));
      await axios.post(`${BASE_URL}/SaveProject`, { data: dataToSave });
      alert('Data saved successfully!');
    } catch (error) {
      alert('Error saving data. Please try again.');
    }
  };

  const fetchData = async () => {
    try {
      if (selectedProjectCode) {
        const response = await axios.get(`${BASE_URL}/BOMProject/${selectedProjectCode}`);
        const responseData = response.data;

        const projects = Array.isArray(responseData.projects) ? responseData.projects : [];
        const initialData = projects.map((item) => ({
          Item_Code: item.Item_Code,
          Item_Name: item.Item_Name,
          Item_Description: item.Item_Description,
          Item_Unit: item.Item_Unit,
          Quantity: item.Quantity,
          rate: 0,
        }));

        setCProject(projects);
        setData(initialData);
      }
    } catch (error) {
      alert('No Data Against corresponding Project!');
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedProjectCode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/ViewProject`);
        const data = response.data;
        const projects = data.projects;

        setProject(projects);

        const codes = projects.map((project) => ({
          value: project.Project_Code,
          label: project.Project_Name
        }));
        setProjectCodes(codes);
      } catch (error) {
        alert('Error Fetching Projects data. Please try again.');
      }
    };

    fetchData();
  }, []);
  const columns = [
    { name: "Sr" },
    { name: "Item Code" },
    { name: "Item Name" },
    { name: "Item Description" },
    { name: "Uom" },
    { name: "Qty" },
    { name: "Rate" },
    { name: "Amount" },
  ];
  return (
    <div style={{ backgroundColor: "lightgray", minHeight: "100vh", padding: "20px" }}>
      <Paper style={{ backgroundColor: "lightgray", padding: "15px", borderRadius: "8px solid black" }}>
        <Box className="custom-form" component={"form"}>
          <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"} gap={1}>
            <Grid className="Grid-item" display={"flex"} alignItems={"center"}>
              <Grid>
                <Typography component={"form"} variant="h5" className="custom-typography" sx={{ fontWeight: "bold" }}>
                  Project:{" "}
                </Typography>
              </Grid>
              <Select
                placeholder="Select Project Code"
                color="secondary"
                variant="outlined"
                size="small"
                fullWidth
                className="item-code-textfield"
                onChange={GetitemCode}
                sx={{ marginLeft: "8px", height: "5vh", borderRadius: "10px", backgroundColor: "white", width: "13rem" }}
              >
                {projectCodes.map((project) => (
                  <MenuItem key={project.value} value={project.value}>
                    {project.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            
            <Grid sx={{ width: { xs: "100%", sm: "auto" } }} justifyContent={"flex-end"}>
            <Box justifyContent={"flex-end"} className="Email-name" ml={"auto"}>
            <p >{User_ID}</p>
            </Box>
              <Button
                variant="contained"
                color="primary"
                className="custom-btn"
                sx={{ width: "100%", borderRadius: 3, color: "white", backgroundColor: "#23205d" }}
                onClick={() => {
                  localStorage.removeItem('token');
                }}
              >
                <Link to={"/"} style={{ textDecoration: "none", color: "inherit", display: "block" }}>
                  Back to the Login
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div className="TableItems-container">
        <h1 className="TableItems-heading">Project Details</h1>
        <Paper className="TableItems-paper">
          <TableContainer
            className="TableItems-table-container"
            component={Paper}
            sx={{ backgroundColor: "white", border: "1px  black", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
          >
            <Table className="TableItems-table" size="small" aria-label="a dense table">
              <TableHead className="TableItems-table-head">
                <TableRow size="small" sx={{ height: "10px !important" }}>
                  {columns.map((column) => (
                    <TableCell sx={{ height: "auto" }} key={column.name}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="TableItems-table-body">
                {CProject.map((item, index) => (
                  <TableRow key={index} sx={{ height: "10px !important" }}>
                    <TableCell sx={{ height: "auto" }}>{index + 1}</TableCell>
                    <TableCell sx={{ height: "auto", width: "6.2rem" }}>{item.Item_Code}</TableCell>
                    <TableCell className="ItemName">{item.Item_Name}</TableCell>
                    <TableCell className="itemDescriptionx">{item.Item_Description}</TableCell>
                    <TableCell sx={{ height: "auto", align: "left" }}>{item.Item_Unit}</TableCell>
                    <TableCell sx={{ height: "auto" }}>{item.Quantity}</TableCell>
                    <TableCell sx={{ height: "auto", width: "7rem" }}>
                      <input
                        onChange={(e) => handleRateChange(index, e.target.value)}
                        onBlur={() => handleBlur(index)}
                        size="small"
                        style={{ width: "5rem" }}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "7rem" }}>{data[index].amount || 0}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div style={{  display: 'flex', justifyContent: 'center', marginTop: '10px'}}> 
          <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={saveDataToDatabase}>
            Submit Data
          </Button>
          </div>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default BOQpage;
/* eslint-disable no-unused-vars */
import React ,{useState}from 'react'
import { Grid, Paper, Container, TextField, Typography ,Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
  const fieldNames = [
    'Contractor_Name',
    'Company_Name',
    'Designation',
    'Email',
    'Contact_No',
    'PEC_Reg_No',
    'NTN_No',
    'Tax_Returns',
    'Address',
    'User_ID',
    'User_Password',
  ];
const ContractorRegistration = () => {
    const navigate  = useNavigate();

    const [formData, setFormData] = useState({
      'Contractor_Name': '',
      'Company_Name': '',
      'Designation': '',
      'Email': '',
      'Contact_No': '',
      'PEC_Reg_No': '',
      'NTN_No': '',
      'Tax_Returns': '',
      'Address': '',
      'User_ID':'',
      'User_Password':'',
    });
  
    const handleInputChange = (fieldName, value) => {
      setFormData(prevData => ({
        ...prevData,
        [fieldName]: value,
      }));
    };
  
    const handleFormSubmit = async () => {
      try {
        const response = await fetch(`${BASE_URL}/contracter`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          navigate('/');
          alert('User Register successfully!');
        } else {
          console.error('Failed to submit form data.');
          alert('Error While Registring User. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting form data:', error);
      }
    };
    
  return (
    <div style={{ backgroundColor: '#d8d6d6', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white' }}>
        <Typography variant="h4" gutterBottom sx={{color:"#23205d ",font:"bold",fontWeight:"10px"}}>
          Contractor Registration
        </Typography>
        <Grid container spacing={1}>
        {fieldNames.map((fieldName, index) => (
              <Grid key={index} item xs={4}>
                 <Typography variant="h6" sx={{font:"bold", color:'#23205d' ,fontWeight:"12px"}} >
                  {fieldName}
                </Typography>
                <TextField
                  placeholder={fieldName}
                  variant="standard"
                  fullWidth
                  value={formData[fieldName]}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
               size='small'
               color='secondary'
               sx={{borderRadius: "2px", backgroundColor: "#d8d6d6", width: "90%",  transition: "transform 0.3s, box-shadow 0.3s",
               '&:hover': {
                 transform: 'scale(1.05)',
                 boxShadow: '0 4px 8px rgba(52, 144, 220, 0.7)',
               },}} />
            </Grid>
          ))}
          </Grid>
        <div style={{  display: 'flex', justifyContent: 'center', marginTop: '10px'}}> 
        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleFormSubmit}>
            Submit
          </Button>
          </div>
      </Paper>
    </Container>
  </div>
  )
}

export default ContractorRegistration

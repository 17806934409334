import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Emailpage from "./Components/Emailpage";
import BOQpage from "./Components/BOQpage";
import ContractorRegistration from "./Components/ContractorRegistration";
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Emailpage />} />
          <Route path="/BOQ" element={<BOQpage />} />
          <Route path="/Contract" element={<ContractorRegistration />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

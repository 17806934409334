/* eslint-disable no-script-url */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MailLockIcon from '@mui/icons-material/MailLock';
import "../Components/App.css";
import { BASE_URL } from '../config';
const Emailpage = () => {
  const [User_ID, setEmail] = useState("");
  const [User_Password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async() => { 
    try {
      const response = await fetch(`${BASE_URL}/Login`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          User_ID,
          User_Password,
        }),
      });
      if (response.ok) {
        const { token } = await response.json();

        localStorage.setItem('token', token);
        navigate("/BOQ",{ state: { User_ID } });
      }
      else { alert('Invalid Credential'); }
    } catch (error) {
      alert('Something Went wrong please check Server');
      console.log('error ', error);
    }
    finally {
      setEmail("");
      setPassword("");
    }
  };

  return (
    <Box className="backgroundContainer">
      <form action="javascript://" className="form-class">
        <Box className="customBox" sx={{ py: 2, px: 7 }}>
          <Typography py={5} className="customTypography" variant="h4" textAlign={"center"} sx={{ fontWeight: "bold" }}> Login </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField py={5} type={"User_ID"} placeholder="User_ID" color="secondary" size="small"
              value={User_ID}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <Box display="flex" alignItems="center">
                    <AccountBoxIcon sx={{ color: "#23205d", fontSize: 40, marginRight: 0, ml: -2 }} />
                  </Box>
                ),
              }}
              sx={{ "& input::placeholder": { color: "black" } }}
            />
          </Box>
          <TextField py={6} type={"User_Password"} placeholder="User_Password" color="secondary" size="small"
            value={User_Password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <Box display="flex" alignItems="center">
                  <MailLockIcon sx={{ color: "#23205d", fontSize: 40, marginRight: 0, ml: -2, py: 0, px: 0 }} />
                </Box>
              ),
            }}
            sx={{ "& input::placeholder": { color: "black" } }}
          />
          <Typography mt={1} sx={{ fontSize: 15, fontWeight: "bold", color: "#23205d" }}>Forgot password?</Typography>
          <Button className="custom-Button" variant="contained" color="primary"
            sx={{
              marginTop: 3, marginBottom: 3, borderRadius: 3, width: "14rem", backgroundColor: "#23205d",
              ":hover": { backgroundColor: "#007BFF" },
            }}
            onClick={handleSubmit}>
            Login
          </Button>
          <Typography mb={2} color={"#23205d"} fontSize={17}>Don't have an account? <Link to="/Contract" style={{ fontWeight: "bold" }}>Sign up</Link></Typography>
        </Box>
      </form>
    </Box>
  );
};

export default Emailpage;
